<template>
	<div class="page">
		<Tables ref="xTable" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('system.verupdate/add')" addName="新增版本" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">
			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.key" size="small" clearable placeholder="按队版本/文件地址查询">
				</el-input>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="version_name" title="版本名称" align="center" />
			<vxe-table-column slot="table-item" field="version_code" title="APP版本号" align="center" />
			<vxe-table-column slot="table-item" field="os_text" title="更新平台" align="center" />
			<vxe-table-column slot="table-item" field="update_type_text" title="更新方式" align="center" />
			<vxe-table-column slot="table-item" title="版本文件地址" align="center" min-width="50px">
				<template v-slot="{ row }">
					<a :href="row.download_url" target="_blank">{{row.download_url}}</a>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="发布状态" align="center">
				<template v-slot="{ row }">
					<span
						:class="row.status == 0 ? 'color-failed' :(row.status == 1 ? 'color-finish':'color-disable') ">
						{{row.status_text}}
					</span>
				</template>
			</vxe-table-column>

			<vxe-table-column slot="table-item" field="create_time" title="创建时间" align="center" />
			<vxe-table-column slot="table-item" title="操作" align="center" min-width="50px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('system.verupdate/edit')">编辑
					</el-button>
					<el-button size="small" plain @click="deleteOne(row)" v-if="$hasAccess('system.verupdate/del')">删除
					</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 新增 -->
		<el-dialog title="新增" :visible.sync="addDialogShow" :close-on-click-modal="false" :close-on-press-escape="false"
			width="500px">
			<el-form :model="formData" :rules="rules" ref="addForm" label-width="100px" class="form">
				<el-form-item label="版本名称" prop="version_name">
					<el-input v-model="formData.version_name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="APP版本号" prop="version_code">
					<el-input-number type="" v-model="formData.version_code" style="width: 80%;">
					</el-input-number>
				</el-form-item>
				<el-form-item label="发布平台" prop="os">
					<el-select v-model="formData.os" placeholder="发布平台">
						<el-option label="安卓" :value="1">
						</el-option>
						<el-option label="IOS" :value="2">
						</el-option>
						<el-option label="安卓&IOS" :value="3">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="发布状态" prop="status">
					<el-select v-model="formData.status" placeholder="发布状态">
						<el-option label="待发布" :value="0">
						</el-option>
						<el-option label="发布" :value="1">
						</el-option>
						<el-option label="无效" :value="-1">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="更新/安装包" prop="instruction">
					<el-input class="uploadInput" v-model="formData.instruction" style="width: 80%"
						placeholder="请上传.wgt,.apk格式文件">
						<el-upload slot="append" class="uploadbox" ref="upload" name="file" accept=".wgt,.apk"
							:show-file-list="false" :multiple="false" mulitiple action="upload" :on-change="onChange"
							:auto-upload="false">
							<el-button class="uploadbtn"></el-button>
						</el-upload>
					</el-input>
				</el-form-item>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" :disabled="upLoading" size="small" plain icon="el-icon-check"
					@click="saveAdd('addForm')" v-if="$hasAccess('system.verupdate/add')">保 存
				</el-button>
				<el-button size="small" :disabled="upLoading" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>

		<!-- 编辑 -->
		<el-dialog title="编辑" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="500px">
			<el-form :model="formData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<el-form-item label="版本名称" prop="version_name">
					<el-input v-model="formData.version_name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="APP版本号" prop="version_code">
					<el-input-number type="" v-model="formData.version_code" style="width: 80%;">
					</el-input-number>
				</el-form-item>

				<el-form-item label="发布平台" prop="os">
					<el-select v-model="formData.os" placeholder="发布平台">
						<el-option label="安卓" :value="1">
						</el-option>
						<el-option label="IOS" :value="2">
						</el-option>
						<el-option label="安卓&IOS" :value="3">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="发布状态" prop="status">
					<el-select v-model="formData.status" placeholder="发布状态">
						<el-option label="待发布" :value="0">
						</el-option>
						<el-option label="发布" :value="1">
						</el-option>
						<el-option label="无效" :value="-1">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="更新/安装包">
					<el-input class="uploadInput" v-model="formData.instruction" style="width: 80%"
						placeholder="请上传.wgt,.apk格式文件">
						<el-upload slot="append" class="uploadbox" ref="upload" name="file" accept=".wgt,.apk"
							:show-file-list="false" :multiple="false" mulitiple action="upload" :on-change="onChange"
							:auto-upload="false">
							<el-button class="uploadbtn"></el-button>
						</el-upload>
					</el-input>
				</el-form-item>
				<el-form-item label="原地址:">
					{{formData.old_download_url}}
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" :disabled="upLoading" size="small" plain icon="el-icon-check"
					@click="saveEdit('editForm')" v-if="$hasAccess('system.verupdate/edit')">保存编辑
				</el-button>
				<el-button size="small" :disabled="upLoading" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import axios from 'axios';
	import config from '@/config'
	import Tables from '@/components/tables';
	import {
		forEach
	} from '../../../utils/tools';
	export default {
		name: 'team-index',
		components: {
			Tables,
		},
		data() {
			return {
				upLoading: false,
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {
					key: ""
				},
				addDialogShow: false, // 新增
				editDialogShow: false, // 编辑
				instructionFile: undefined,
				formData: {
					id: '',
					version_code: '',
					os: '',
					status: '',
					instruction: ''
				},
				rules: {
					version_name: [{
						required: true,
						message: '请输入版本名称',
						trigger: 'change'
					}],
					version_code: [{
						required: true,
						message: '请输入APP版本号',
						trigger: 'change'
					}],
					os: [{
						required: true,
						message: '请输入发布平台',
						trigger: 'change'
					}],
					status: [{
						required: true,
						message: '请输入发布状态',
						trigger: 'change'
					}],
				},

			}
		},
		computed: {},
		methods: {
			//获取数据列表
			getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				let searchParams = {
					_keywords: this.searchFormData.key
				};
				this.isLoading = true
				this.$api.System.VerupdateIndex(params, searchParams).then(res => {
					this.totalPage = res.total
					this.tableData = res.data
					this.isLoading = false
				}).catch(err => {
					this.isLoading = false
				})
			},
			//重置查询参数
			clearSearch() {
				this.searchFormData = {
					key: ''
				}
			},
			// 重置表单
			resetFormData() {
				this.formData = {
					version_name: "",
					version_code: '',
					os: '',
					status: '',
					instruction: ''
				};
				if (this.$refs.addForm) {
					this.$refs.addForm.resetFields();
				}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields();
				}
			},
			//添加版本
			addOne(row) {
				this.resetFormData();
				this.addDialogShow = true;
			},
			//编辑版本
			editOne(row) {
				this.resetFormData();
				this.editDialogShow = true;
				this.formData = {
					id: row.id,
					version_name: row.version_name,
					version_code: row.version_code,
					os: row.os,
					status: row.status,
					old_download_url: row.download_url,
					instruction: ''
				};
			},
			//删除版本
			deleteOne(row) {
				const params = {
					token: this.$store.state.user.token,
					id: row.id
				}
				this.$api.System.VerupdateDel(params).then(() => {
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				});
			},
			// 关闭弹窗
			closeDialog() {
				this.addDialogShow = false
				this.editDialogShow = false
			},
			//上传文件验证
			onChange(file) {
				// 校验格式
				if (file.raw.name.indexOf('.wgt') == -1 && file.raw.name.indexOf('.apk') == -1) {
					this.$message.error('请上传正确的APP格式');
					return false;
				}
				this.formData.instruction = file.name;
				this.instructionFile = file.raw;
			},
			// 新增保存
			saveAdd(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.upLoading = true;
						let formData = new FormData();
						for (var key in this.formData) {
							formData.append(key, this.formData[key]);
						}
						formData.append('token', this.$store.state.user.token);
						if (this.formData.os != 2 && this.formData.instruction == '') {
							this.upLoading = false;
							this.$message.error('请上传正确的更新文件');
							return false;
						} else if (this.formData.instruction != '') {
							formData.append('entityFile', this.instructionFile);
						}
						axios({
							url: config.baseUrl + '/api/system/verupdate/add',
							method: 'post',
							headers: {
								'Content-Type': 'multipart/form-data'
							},
							data: formData
						}).then(res => {
							this.upLoading = false;
							if (res.status === 200 && !!res.data) {
								if (res.data.code === 0) {
									this.$notify({
										title: "成功",
										message: "添加成功",
										type: "success",
									});
								} else {
									this.$notify({
										title: "失败",
										message: "添加失败",
										type: "error",
									});
								}
							} else {
								this.$notify({
									title: "错误",
									message: res.data.msg,
									type: "error",
								});
							}
							this.closeDialog();
							this.$refs.xTable.refreshTable()
						})
					}
				});
			},
			// 编辑保存
			saveEdit(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.upLoading = true;
						let formData = new FormData();
						for (var key in this.formData) {
							formData.append(key, this.formData[key]);
						}
						formData.append('token', this.$store.state.user.token);
						if (this.formData.instruction != '') {
							formData.append('entityFile', this.instructionFile);
						}
						axios({
							url: config.baseUrl + '/api/system/verupdate/edit',
							method: 'post',
							headers: {
								'Content-Type': 'multipart/form-data'
							},
							data: formData
						}).then(res => {
							this.upLoading = false;
							if (res.status === 200 && !!res.data) {
								if (res.data.code === 0) {
									this.$notify({
										title: "成功",
										message: "编辑成功",
										type: "success",
									});
								} else {
									this.$notify({
										title: "失败",
										message: "编辑失败",
										type: "error",
									});
								}
							} else {
								this.$notify({
									title: "错误",
									message: res.data.msg,
									type: "error",
								});
							}
							this.closeDialog();
							this.$refs.xTable.refreshTable()
						})
					}
				});
			}

		}
	}
</script>
<style>
	.color-finish {
		color: #2ba245;
	}

	.color-disable {
		color: #999;
	}

	.color-error {
		color: #f03b35;
	}

	.color-warning {
		color: #ec808d;
	}

	.color-normal {
		color: #108ee9;
	}

	.color-failed {
		color: #f47920;
	}
</style>
